import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import VueSweetalert2 from "vue-sweetalert2"
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(VueSweetalert2)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
